import React from 'react'
import clsx from 'clsx'
import { useController } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { Label } from '@/components'

interface NumberInputProps {
  label: string
  name: string
  value: any
  className?: string
  description?: string
  min?: number
  max?: number
  error?: string
  toFixedValue?: number
  placeholder?: string
  disabled?: boolean
  required?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const NumberInput: React.FC<NumberInputProps> = ({
  label,
  name = '',
  min,
  max,
  value = 800,
  description,
  error = '',
  disabled = false,
  className,
  toFixedValue = 2,
  required,
  onChange = () => null,
}: NumberInputProps) => {
  return (
    <div
      className={clsx(
        'relative',
        disabled ? 'opacity-50 saturate-50 blur-[2px] select-none pointer-events-none' : '',
        className,
      )}
    >
      <Label name={name} description={description} error={error} label={label} className="mb-2" />

      <div className="relative">
        <div className="inline-block w-full relative">
          <NumberFormat
            value={Number(value).toFixed(toFixedValue)}
            thousandSeparator={true}
            allowNegative={false}
            min={min}
            max={max}
            required={required}
            disabled={disabled}
            onValueChange={(target) => {
              if (target.floatValue) {
                onChange(target.floatValue as any)
              }
            }}
            isNumericString
            prefix="$ "
            className={clsx(
              ' border-2 transition   border-zinc-200 dark:border-zinc-700/60  dark:bg-zinc-800 bg-white  font-sans appearance-none block w-full  rounded-lg    placeholder-zinc-400 dark:placeholder-zinc-400/80   peer relative z-[1] font-medium  selection:bg-black text-black dark:text-white focus:ring-1 focus:ring-offset-1 placeholder:font-serif placeholder:font-regular  focus:outline focus:outline-brand  focus:outline-2 focus:outline-offset-0 focus:ring-transparent  text-base caret-brand  peer shadow-subtler dark:shadow-lg dark:shadow-zinc-800/70 p-3',
              error ? 'border-red-600 focus:ring-rose-600 focus:border-rose-600 text-rose-600' : '',
            )}
          />
          {error && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <ExclamationCircleIcon className="h-5 w-5 text-rose-600" aria-hidden="true" />
            </div>
          )}
        </div>
      </div>

      {error && (
        <p className="text-sm text-rose-600" id={`${name}-description`}>
          {error}
        </p>
      )}
    </div>
  )
}

NumberInput.displayName = 'NumberInput'

interface ControlledNumberInputProps {
  label: string
  name: string
  rules?: any
  className?: string
  control?: any
  min?: number
  max?: number
  placeholder?: string
  description?: string
  toFixedValue?: number
  displayPrice?: boolean
  displayFormat?: string
  disabled?: boolean
  required?: boolean
  defaultValue?: number
}

export const ControlledNumberInput = ({
  label,
  control,
  name,
  rules,
  className,
  min,
  max,
  placeholder,
  description,
  toFixedValue = 0,
  defaultValue = 10,
  disabled = false,
  required = false,
}: ControlledNumberInputProps) => {
  const {
    field: { onChange, name: inputName, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValue,
  })

  return (
    <NumberInput
      description={description}
      placeholder={placeholder}
      className={className}
      label={label}
      onChange={onChange}
      value={value}
      min={min}
      max={max}
      required={required}
      name={inputName}
      toFixedValue={toFixedValue}
      disabled={disabled}
      error={error?.message || error?.type === 'required' ? 'Required' : ''}
    />
  )
}
