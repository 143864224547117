import { Button } from '@/components'
import { useLocalStorage } from '@/hooks/useLocalStorage'

export const CookieBanner = () => {
  const [acceptCookies, setAcceptCookies] = useLocalStorage('gn-accept-cookies', null)

  if (acceptCookies) {
    return null
  }

  return (
    <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5 z-50">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="p-3 rounded-lg dark:bg-zinc-900 bg-white border dark:border-zinc-600 border-zinc-200 shadow-lg sm:p-3">
          <div className="sm:flex items-center justify-between flex-wrap">
            <div className="flex-1 flex items-center">
              <span className="text-2xl">🍪</span>
              <p className="ml-3 dark:text-neutral-50 text-zinc-800 sm:truncate text-sm">
                We use cookies for good things only like logging in.
              </p>
            </div>
            <div className="flex mt-2 sm:mt-0 space-x-2 ml-8 sm:ml-0">
              <div className="flex-shrink-0 sm:mt-0 sm:w-auto">
                <Button small internal url="/cookies" outline>
                  Learn More
                </Button>
              </div>
              <div className="flex-shrink-0 sm:ml-4">
                <Button small onClick={() => setAcceptCookies(true)}>
                  Accept
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
