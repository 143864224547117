import { Fragment, useEffect, useState } from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { LocationMarkerIcon } from '@heroicons/react/outline'
import shallow from 'zustand/shallow'
import { SearchIcon } from '@heroicons/react/outline'
import { geocodingClient } from '@/lib/MapboxGeocode'
import useStore from '@/store'
import { useDebouncedCallback } from '@/hooks/useDebouncedCallback'
import clsx from 'clsx'
import Link from 'next/link'
import formatAddress from '@/lib/formatAddress'
import { useRouter } from 'next/router'
import { useScrollPosition } from '@/hooks/useScrollPosition'
import { gql, useLazyQuery } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'

export const SearchBox = () => {
  const [getSearchResults, { loading, data }] = useLazyQuery(gql`
    query SearchListings($query: String!) {
      searchListings(query: $query) {
        id
        listingType
        unitNumber
        streetNumber
        streetName
        city
        coordinates
        country
        region
        neighbourhood
        postalzip
        forRent
        latitude
        longitude
        shortcode
        normalized_address
      }
    }
  `)
  const [scrollThreshold, setScrollThreshold] = useState(false)
  const router = useRouter()
  const [geoResults, setGeoResults] = useState([])
  const [hasSearched, setHasSearched] = useState(false)
  const [active, value, clear, setSearchValue, clearSearchValue, closeSearch] = useStore(
    (state) => [
      state.searchActive,
      state.searchValue,
      state.closeSearch,
      state.setSearchValue,
      state.clearSearchValue,
      state.closeSearch,
    ],
    shallow,
  )

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y < -600) {
        setScrollThreshold(true)
      } else {
        setScrollThreshold(false)
      }
    },
    [scrollThreshold],
    undefined,
    false,
    200,
  )

  const searchRequest = useDebouncedCallback(async (value: string) => {
    if (value.length >= 3) {
      try {
        getSearchResults({
          variables: {
            query: value,
          },
        })
        const data = await geocodingClient
          .forwardGeocode({
            query: value,
            types: ['place', 'country', 'region'],
            countries: ['ca', 'us'],
            limit: 10,
            autocomplete: false,
          })
          .send()
        setGeoResults(data.body.features.filter((feature) => feature.bbox))
        setHasSearched(true)
      } catch (err) {
        Sentry.captureException(err)
      }
    }
  }, 250)

  const handleChange = (value: string) => {
    setSearchValue(value)
  }

  const handleClear = () => {
    clearSearchValue()
    setGeoResults([])
  }

  useEffect(() => {
    if (!value) {
      setHasSearched(false)
    }
    searchRequest(value)
  }, [value, searchRequest])

  return (
    <Transition.Root show={active} as={Fragment} afterLeave={handleClear}>
      <Dialog as="div" className="fixed inset-0 z-40 overflow-y-auto px-6 pt-24 md:p-20 md:pt-28" onClose={clear}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 dark:bg-zinc-800 bg-zinc-600 dark:bg-opacity-50 backdrop-blur-sm  bg-opacity-50 transition-opacity" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Combobox onChange={(item) => handleChange(item)} value={value}>
            <div className="mx-auto max-w-2xl transform divide-y divide-zinc-500 divide-opacity-20 overflow-hidden rounded-xl dark:bg-[#111] bg-snow shadow-2xl transition-all">
              <div className={clsx('relative lg:hidden')}>
                <Combobox.Input
                  className=" h-16 w-full border-0 bg-transparent pl-11 pr-4 text-zinc-900 dark:text-white placeholder-zinc-500 focus:ring-0 sm:text-lg focus:outline-none caret-brand-500"
                  placeholder="Explore reviews..."
                  onChange={(event) => handleChange(event.target.value)}
                />
                <div className="absolute left-3 top-1/2 -translate-y-1/2 z-10">
                  <SearchIcon className="h-5 w-5 text-zinc-400 dark:text-zinc-300" />
                </div>
              </div>
              {data?.searchListings.length || geoResults.length ? (
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Combobox.Options
                    static
                    className={clsx('max-h-[40vh] scroll-py-10 scroll-pb-2 overflow-y-auto p-3 pb-0 flex flex-col ')}
                  >
                    {data?.searchListings.map((listing) => (
                      <Combobox.Option
                        key={listing.id}
                        value={listing}
                        className={({ active }) =>
                          clsx(
                            'cursor-pointer select-none rounded-md p-3 group mb-4',
                            router.pathname === '/explore' ? 'order-2' : '',
                            active && 'bg-brand text-white',
                          )
                        }
                      >
                        <Link href={`/listing/${listing.id}`} passHref>
                          <a
                            role="button"
                            className={clsx(
                              'block cursor-pointer select-none font-semibold font-serif leading-none group-hover:text-white tracking-tight',
                            )}
                            onClick={closeSearch}
                            onKeyDown={closeSearch}
                          >
                            {formatAddress(listing)}
                            <span className="block mt font-medium text-sm ">Listing</span>
                          </a>
                        </Link>
                      </Combobox.Option>
                    ))}
                    {geoResults.map((result) => (
                      <Combobox.Option
                        key={result.id}
                        value={result}
                        className={({ active }) =>
                          clsx(
                            'cursor-pointer select-none rounded-md p-3  group mb-4',
                            router.pathname === '/explore' ? 'order-1' : '',
                            active && 'bg-brand text-white',
                          )
                        }
                      >
                        <Link href={`/explore?bbox=${encodeURIComponent(result.bbox)}`} passHref>
                          <a
                            role="button"
                            className={clsx(
                              'block cursor-pointer select-none font-semibold font-serif leading-none group-hover:text-white tracking-tight',
                            )}
                            onClick={closeSearch}
                            onKeyDown={closeSearch}
                          >
                            {result.place_name}
                            <span className="block mt font-medium text-sm ">Search this area</span>
                          </a>
                        </Link>
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                </Transition.Child>
              ) : null}
              {hasSearched && value !== '' && geoResults.length === 0 && data?.searchListings.length === 0 && (
                <div className="py-14 px-6 text-center text-sm sm:px-14">
                  <LocationMarkerIcon className="mx-auto h-10 w-10 text-brand " aria-hidden="true" />
                  <p className="mt-4 font-semibold font-sans dark:text-white text-black">No addresses found</p>
                  <p className="mt-2 dark:text-white text-black">
                    We couldn’t find anything with that term. Please try again.
                  </p>
                </div>
              )}
            </div>
          </Combobox>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  )
}
