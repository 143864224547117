import { Badge, ButtonLink, Card, Rating, StaticMap, Title } from '@/components'
import { Nearby } from '@/components/ListingPage/Nearby'
import { Review } from '@/components/ListingPage/Review'
import useFavourite from '@/hooks/useFavourite'
import { ClipboardCopyIcon, InformationCircleIcon } from '@heroicons/react/outline'
import Image from 'next/image'
import { useMemo } from 'react'
import { FaBed, FaShower } from 'react-icons/fa'
import { Carousel } from 'react-responsive-carousel'
import { ForRentCard } from './ForRentCard'
import { WalkScoreModule } from './Walkscore'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

import gasIcon from '../../../public/icons/3d/gas.png'
import hydroIcon from '../../../public/icons/3d/hydro.png'
import waterIcon from '../../../public/icons/3d/water.png'

const myLoader = ({ src }) => {
  return `${process.env.NEXT_PUBLIC_IMAGEKIT_URL_ENDPOINT}/${src}`
}

export const Listing = ({ listingData }: any) => {
  const { favourites, addFavourite, removeFavourite, favLoaded } = useFavourite()

  const images = useMemo(
    () =>
      listingData.reviews?.length === 1
        ? listingData.reviews[0]?.images || []
        : listingData.reviews?.filter((review) => review.images.length).map((review) => review.images) || [],
    [listingData],
  )
  return (
    <div className="lg:grid grid-cols-12 px-6 sm:px-12">
      <main className="col-span-7 pb-6">
        <header className="relative">
          {listingData.forRent && (
            <div className="mb-4">
              <Badge large>For Rent</Badge>
            </div>
          )}

          <div className="flex space-x-2 mb-4">
            <Rating value={listingData?.averageRating} className="mb-3" />
            <span className="ml-2 text-sm font-semibold text-black dark:text-white">
              Based on {listingData.ratingsAmount} {listingData.ratingsAmount > 1 ? 'reviews' : 'review'}
            </span>
          </div>

          <Title as="h2" size="2xl">
            {(listingData.unitNumber ? `${listingData.unitNumber} - ` : '') +
              `${listingData.streetNumber} ${listingData.streetName}`}
          </Title>
          <p className=" mt-1.5 text-base lg:text-lg font-sans font-semibold text-black dark:text-white">
            {`${listingData?.city} ${listingData?.region}, ${listingData?.country} ${listingData?.postalzip}`}
          </p>
        </header>
        {listingData.parentListingId ? (
          <div className="mt-3">
            <ButtonLink internal url={`/listing/${listingData?.parentListingId}`} small>
              View Parent Building.
            </ButtonLink>
          </div>
        ) : null}

        <ul className="grid lg:grid-cols-4 grid-cols-2 sm:grid-cols-3 gap-4 mt-16">
          <li>
            <p className="text-sm text-black font-serif dark:text-white tracking-tight font-medium">Listing Type.</p>
            <p className="capitalize font-sans rounded outline-transparent tracking-tight font-semibold inline-flex items-center text-black dark:text-white">
              {listingData?.listingType?.toLowerCase()}
            </p>
          </li>
          {listingData?.neighbourhood && (
            <li>
              <p className="text-sm text-black dark:text-white font-serif tracking-tight font-medium">Neighbourhood.</p>
              <p className="capitalize font-sans rounded outline-transparent tracking-tight font-semibold inline-flex items-center text-black dark:text-white">
                {listingData?.neighbourhood}
              </p>
            </li>
          )}

          <li>
            <p className="text-sm text-black dark:text-white tracking-tight font-regular font-serif">Bedrooms.</p>
            <p className="capitalize font-sans rounded outline-transparent tracking-tight font-semibold inline-flex items-center text-black dark:text-white">
              <span className="relative -top-px  inline-block mr-1.5">
                <FaBed className="w-5 h-5" aria-hidden />
              </span>
              {listingData?.bedrooms}
            </p>
          </li>

          <li>
            <p className="text-sm text-black dark:text-white tracking-tight font-medium font-serif">Bathrooms.</p>
            <p className="capitalize font-sans rounded outline-transparent tracking-tight font-semibold inline-flex items-center text-black dark:text-white">
              <span className="relative -top-px  inline-block mr-1.5">
                <FaShower className="w-5 h-5" aria-hidden />
              </span>
              {listingData?.bathrooms}
            </p>
          </li>

          <li>
            <p className="text-sm text-black dark:text-white tracking-tight font-medium font-serif">
              Most recent rent price.
            </p>
            <p className="capitalize font-sans rounded outline-transparent tracking-tight font-semibold inline-flex items-center text-black dark:text-white">
              ${listingData.averageRentPrice}
            </p>
          </li>
        </ul>

        {images?.length ? (
          <div className="my-6 rounded-md overflow-hidden">
            <Carousel dynamicHeight centerMode>
              {images?.map((image, idx) => (
                <div key={image.name} className="relative">
                  <div className="overflow-hidden shadow-subtle h-[500px]">
                    <Image
                      loader={myLoader}
                      width="830"
                      height="500"
                      src={`tr:w-830,h-500,q-80${image?.filePath}`}
                      className="mb-0 leading-none"
                    />
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        ) : null}

        {listingData.forRent &&
          listingData.activeRental(
            <div className="space-y-6 mt-12">
              <Title as="h1" size="lg">
                Rental Details
              </Title>
              <ForRentCard {...listingData.activeRental} />
            </div>,
          )}

        <div className="mt-20">
          <Title as="h3" size="lg">
            {listingData.forRent ? 'Monthly Utility Costs' : 'Average Monthly Utility Costs'}
          </Title>
          <p className="text-xs dark:text-zinc-200 text-zinc-600 font-medium mt-2 flex ">
            <InformationCircleIcon className="h-4 w-4 inline-block mr-1" aria-hidden />{' '}
            {listingData.forRent
              ? 'Values submitted by property owner. May not reflect actual and/or current costs.'
              : 'Averages calculated from user submitted reviews for this listing. May not reflect actual and/or current costs.'}
          </p>
          <dl className="mt-6 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {listingData.hydroIncluded || (listingData.hydroAvgPrice && listingData.hydroAvgPrice != 0) ? (
              <Card feature borderColor="border-red-500">
                <>
                  <dt className="flex items-center space-x-4">
                    <div className="rounded-full p-2.5 bg-red-200 shadow-lg shadow-red-500/50 h-8 w-8 relative">
                      <div className="absolute -left-1 -top-1 z-10 h-10 w-10">
                        <Image src={hydroIcon} />
                      </div>
                    </div>
                    <p className="ml-12 text-sm font-medium text-black dark:text-white truncate">Electricity Costs</p>
                  </dt>
                  <dd className="ml-12 flex items-baseline">
                    <p className="text-xl text-black dark:text-white font-bold">
                      {listingData.hydroIncluded ? 'Included' : `$${Number(listingData.hydroAvgPrice).toFixed(2)}`}
                    </p>
                  </dd>
                </>
              </Card>
            ) : null}
            {listingData.waterIncluded || (listingData.waterAvgPrice && listingData.waterAvgPrice != 0) ? (
              <Card feature borderColor="border-cyan-500">
                <>
                  <dt className="flex items-center space-x-4">
                    <div className="rounded-full p-2.5 bg-cyan-200 shadow-lg shadow-cyan-500/50 h-8 w-8 relative">
                      <div className="relative -left-2 -top-1.5 z-10 h-8 w-8">
                        <Image src={waterIcon} />
                      </div>
                    </div>
                    <p className="ml-12 text-sm font-medium text-black dark:text-white truncate">Water Costs</p>
                  </dt>
                  <dd className="ml-12 flex items-baseline">
                    <p className="text-xl text-black dark:text-white font-bold">
                      {listingData.waterIncluded ? 'Included' : `$${Number(listingData.waterAvgPrice).toFixed(2)}`}
                    </p>
                  </dd>
                </>
              </Card>
            ) : null}
            {listingData.gasIncluded || (listingData.gasAvgPrice && listingData.gasAvgPrice != 0) ? (
              <Card feature borderColor="border-orange-500">
                <>
                  <dt className="flex items-center space-x-4">
                    <div className="rounded-full p-2.5 bg-orange-200 shadow-lg shadow-orange-500/50 h-8 w-8 relative">
                      <div className="relative -left-3 -top-3 z-10 h-10 w-10 -rotate-12">
                        <Image src={gasIcon} />
                      </div>
                    </div>
                    <p className="text-sm font-medium text-black dark:text-white truncate">Gas Costs</p>
                  </dt>
                  <dd className="ml-12 flex items-baseline">
                    <p className="text-xl text-black dark:text-white font-bold">
                      {listingData.gasIncluded ? 'Included' : `$${Number(listingData.gasAvgPrice).toFixed(2)}`}
                    </p>
                  </dd>
                </>
              </Card>
            ) : null}
          </dl>
        </div>

        <Review id={listingData.id} />
      </main>
      <aside className="col-start-9 col-end-13 sm:mb-32 ">
        <div className="hidden lg:block rounded-lg overflow-hidden shadow-subtler border border-black/20 dark:border-zinc-500/50">
          <StaticMap config={{ height: 460, latitude: listingData?.latitude, longitude: listingData?.longitude }} />
        </div>
        <div className="mt-12">
          <WalkScoreModule id={listingData.id} />
        </div>
        <div className="text-zinc-800 dark:text-zinc-300 mt-12">
          <>
            <Title as="h3" size="md">
              Share
            </Title>
            <div className="flex items-center space-x-6 mt-3">
              <a
                href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                  `Check out this testimonial of ${
                    (listingData?.unitNumber ? `${listingData?.unitNumber} - ` : '') +
                    `${listingData?.streetNumber} ${listingData?.streetName}`
                  } on Good Neighbour.`,
                )}&url=${encodeURIComponent(`https://goodnbr.co/listing/${listingData.id}`)}&via=goodnbrco`}
                rel="noopener noreferrer"
                target="_blank"
                className="inline-block"
              >
                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                </svg>
              </a>
              <a
                href={`https://www.facebook.com/dialog/share?app_id=956308331791051&display=page&href=${encodeURIComponent(
                  `https://goodnbr.co/listing/${listingData.id}`,
                )}&quote=${encodeURIComponent(
                  `Check out this testimonial of ${
                    (listingData?.unitNumber ? `${listingData?.unitNumber} - ` : '') +
                    `${listingData?.streetNumber} ${listingData?.streetName}`
                  } on Good Neighbour.`,
                )}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <span className="sr-only">Share on Facebook</span>
                <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                  <path
                    fillRule="evenodd"
                    d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
              {listingData.forRent ? (
                <div className="flex flex-1 relative">
                  <input
                    className="select-all border border-zinc-300 dark:border-zinc-600  dark:bg-zinc-800  bg-white dark:shadow-black/20 shadow-zinc-200/60 font-sans font-medium appearance-none block w-56 p-2 rounded-l-md  text-base focus:outline-none placeholder-zinc-400 focus:ring-1  peer relative z-[1] dark:text-neutral-50 text-zinc-700 focus:dark:border-transparent focus:border-transparent focus:ring-transparent selection:bg-brand-400 selection:dark:bg-brand-500 flex-1"
                    readOnly
                    onFocus={(event) => event.target.select()}
                    // value={`https://${activeRental.shortCode}.gdnbr.com`}
                  />
                  <span className='hidden peer-focus:block after:content-[""] after:absolute after:-inset-[2px] after:bg-gradient-to-r after:from-brand-500 after:via-teal-400 after:to-yellow-500 after:dark:to-amber-600 after:rounded-lg -z-[1] after:pointer-events-none' />
                  <button
                    className="rounded-r-md bg-zinc-200 hover:bg-zinc-300/70 dark:bg-zinc-700 p-2 border border-l-0 border-zinc-300 dark:border-zinc-600 hover:dark:bg-zinc-700/80 transition"
                    // onClick={() => {
                    //   navigator.clipboard.writeText(`https://${activeRental.shortCode}.gdnbr.com`)
                    //   setNotification('Copied', 'Listing URL copied', 'success', true)
                    // }}
                  >
                    <ClipboardCopyIcon className="h-5 w-5" />
                  </button>
                </div>
              ) : null}
            </div>
          </>
        </div>
        <Nearby id={listingData.id} />
      </aside>
    </div>
  )
}
