import clsx from 'clsx'
import Link from 'next/link'

export const Breadcrumb = ({ path = [] }: { path: { name: string; path: string; current?: boolean }[] }) => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        {path.map((page, pageIdx) => (
          <li key={page.name}>
            <div className="flex items-center">
              {pageIdx > 0 ? (
                <svg
                  className="flex-shrink-0 h-5 w-5 dark:text-zinc-300 text-zinc-700"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                >
                  <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                </svg>
              ) : null}
              <Link passHref href={`${page.path}`}>
                <a
                  className={clsx(
                    pageIdx > 0 ? 'ml-4' : '',
                    'text-sm dark:text-zinc-300 text-zinc-500 hover:text-zinc-700 capitalize font-bold',
                  )}
                  aria-current={page.current ? 'page' : undefined}
                >
                  {page.name}
                </a>
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  )
}
