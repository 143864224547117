import { Dialog, Transition } from '@headlessui/react'
import { useRouter } from 'next/router'
import React, { Fragment, useEffect, useState } from 'react'

import { SearchIcon, XIcon } from '@heroicons/react/outline'
import { MenuAlt2Icon } from '@heroicons/react/solid'

import Link from 'next/link'

import { DarkModeToggle, Logo } from '@/components'
import { useScrollPosition } from '@/hooks/useScrollPosition'
import useStore from '@/store'
import shallow from 'zustand/shallow'

const navigation = {
  pages: [{ name: 'Explore', href: '/explore' }],
}

export default function Nav() {
  const [scrollThreshold, setScrollThreshold] = useState(false)
  const [open, setOpen] = useState(false)
  const router = useRouter()
  const setNotification = useStore((state) => state.setNotification)
  const [active, value, setSearchValue, clearSearchValue, closeSearch, openSearch] = useStore(
    (state) => [
      state.searchActive,
      state.searchValue,
      state.setSearchValue,
      state.clearSearchValue,
      state.closeSearch,
      state.openSearch,
    ],
    shallow,
  )
  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (currPos.y < -600) {
        setScrollThreshold(true)
      } else {
        setScrollThreshold(false)
      }
    },
    [scrollThreshold],
    undefined,
    false,
    200,
  )

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value
    if (!active) {
      openSearch()
    }
    if (searchTerm && typeof searchTerm === 'string') {
      setSearchValue(searchTerm)
    } else {
      closeSearch()
    }
  }

  useEffect(() => {
    const handleRouteChange = () => {
      setOpen(false)
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  return (
    <div className="sticky z-[60] w-full top-0">
      {/* Mobile menu */}
      <div className="bg-brand text-center py-3 px-1">
        <p className="text-sm text-zinc-100 tracking-tight font-medium">
          👋 Good Vibes Can't Last Forever...
          <Link href="/goodbye">
            <a className="underline inline-block ml-5">Read more about why Good Neighbour is going readonly.</a>
          </Link>
        </p>
      </div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-[60] lg:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-[#111] bg-opacity-50 backdrop-blur-sm" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="z-[60] relative max-w-lg w-full bg-[#111] shadow-xl pb-12 flex flex-col overflow-y-auto">
              <div className="px-6 pt-5 pb-2 flex">
                <button
                  type="button"
                  className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-white focus:outline-none"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-8 w-8" aria-hidden="true" />
                </button>
              </div>

              <div className="py-6 px-12 space-y-6">
                {navigation.pages.map((page) => (
                  <div key={page.name} className="flow-root">
                    <Link href={page.href} passHref>
                      <a className="-m-2 p-2 block text-white text-2xl hover:text-white/80 font-semibold">
                        {page.name}
                      </a>
                    </Link>
                  </div>
                ))}
              </div>

              <div className="absolute top-4 right-4">
                <DarkModeToggle background position="right" />
              </div>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <header className="relative">
        <nav aria-label="Top">
          {/* Secondary navigation */}
          <div className="dark:bg-zinc-900/80 backdrop-blur-xl bg-snow border-y border-zinc-100 dark:border-zinc-800/80 shadow-md dark:shadow-lg shadow-zinc-700/10 dark:shadow-zinc-800/20">
            <div className=" mx-auto px-4 sm:px-6 lg:px-8">
              <div className="">
                <div className="py-2 flex items-center justify-between relative">
                  {/* Logo (lg+) */}
                  <div className="hidden pr-6 lg:flex lg:items-center">
                    <Link href="/explore" passHref>
                      <a>
                        <span className="sr-only">Good Neighbour</span>
                        <Logo className="h-10 fill-brand dark:fill-white" />
                      </a>
                    </Link>
                  </div>

                  <Link passHref href="/explore">
                    <a className="lg:hidden block sm:ml-3 absolute left-1/2 -translate-x-1/2">
                      <span className="sr-only">Good Neighbour</span>
                      {/* <ReactSVG src="/logos/newnew.svg" className="h-8 w-full" /> */}
                      <Logo className="h-8 fill-brand dark:fill-white" />
                    </a>
                  </Link>

                  {/* Mobile menu and search (lg-) */}
                  <div className="flex items-center lg:hidden">
                    <button
                      type="button"
                      className="-ml-2 bg-transparent  p-2 rounded-md text-zinc-700 dark:text-zinc-200 "
                      onClick={() => setOpen(true)}
                    >
                      <span className="sr-only">Open menu</span>
                      <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Logo (lg-) */}

                  <div className="hidden h-full flex-1 lg:flex mx-8">
                    <div className="h-full flex justify-center items-center space-x-8">
                      {navigation.pages.map((page) => (
                        <Link key={page.name} href={page.href} passHref>
                          <a className="flex items-center text-base font-bold text-zinc-700 dark:text-zinc-200 hover:text-brand  focus:outline focus:outline-brand focus:rounded-md  focus:outline-2 focus:outline-offset-4 focus:ring-transparent transition">
                            {page.name}
                          </a>
                        </Link>
                      ))}
                    </div>
                  </div>

                  {/* {router.asPath !== '/' ? ( */}
                  <div className="hidden lg:block w-full pr-6 sm:max-w-sm lg:max-w-2xl relative">
                    <label className="sr-only" htmlFor="search">
                      {router.asPath === '/explore' ? 'Search' : 'Explore reviews...'}
                    </label>
                    <input
                      id="search"
                      type="search"
                      value={typeof value === 'string' ? value : ''}
                      onChange={handleSearch}
                      className="border-2 transition   border-zinc-200 dark:border-zinc-700/60  dark:bg-zinc-800 bg-white  font-sans appearance-none block w-full  rounded-lg    placeholder-zinc-400 dark:placeholder-zinc-400/80   peer relative z-[1] font-medium  selection:bg-black text-black dark:text-white focus:ring-1 focus:ring-offset-1 placeholder:font-serif placeholder:font-regular  focus:outline focus:outline-brand  focus:outline-2 focus:outline-offset-0 focus:ring-transparent pl-10 pr-3 py-2.5 text-base caret-brand  peer shadow-subtler dark:shadow-lg dark:shadow-zinc-800/70"
                      placeholder={router.asPath === '/explore' ? 'Search' : 'Explore reviews...'}
                    />
                    <div className="absolute left-3 top-1/2 -translate-y-1/2 z-10">
                      <SearchIcon className="h-5 w-5 text-black dark:text-white/80" />
                    </div>
                  </div>

                  <div className="sm:flex-1 flex items-center justify-end">
                    <div className={['flex items-center space-x-2 ', 'sm:space-x-4'].join(' ')}>
                      <div className="hidden sm:block">
                        <DarkModeToggle position="right" background />
                      </div>

                      <button
                        className="lg:hidden p-2 ml-4 h-10 w-10 flex items-center justify-center hover:bg-zinc-200 dark:hover:bg-zinc-800 rounded-full focus:outline focus:outline-brand   focus:outline-2 focus:outline-offset-4 focus:ring-transparent transition focus:bg-zinc-200 dark:focus:bg-zinc-800"
                        onClick={openSearch}
                      >
                        <span className="sr-only">Explore reviews...</span>
                        <SearchIcon className="w-5 h-5" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}
