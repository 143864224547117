import { useRef } from 'react'
import { Dialog } from '@headlessui/react'
import { CheckCircleIcon, ExclamationIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { motion } from 'framer-motion'

interface AlertModalProps {
  title: string
  description: string
  type: 'success' | 'error'
  handleSuccess: () => void
  handleCancel: () => void
  successMessage: string
  cancelMessage: string
}

export const AlertModal = ({
  title = '',
  description = '',
  type = 'success',
  handleSuccess,
  handleCancel,
  successMessage = '',
  cancelMessage = '',
}: AlertModalProps) => {
  const cancelButtonRef = useRef(null)

  return (
    <>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => null}
        open={!!title.length}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-6 pb-20 text-center sm:block sm:p-0">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, ease: 'anticipate' }}
          >
            <Dialog.Overlay className="fixed inset-0 dark:bg-zinc-800 bg-zinc-600 dark:bg-opacity-50 backdrop-blur-sm  bg-opacity-50 transition-opacity" />
          </motion.div>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>

          <motion.div
            className="relative inline-block align-bottom dark:bg-zinc-800 bg-white shadow-2xl rounded-lg px-6 pt-5 pb-4 text-left overflow-hidden  transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5, ease: 'anticipate' }}
          >
            <div className="sm:flex sm:items-start">
              <div
                className={clsx(
                  'mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10',
                  type === 'success' ? 'bg-emerald-100 ' : 'bg-red-100 ',
                )}
              >
                {type === 'success' ? (
                  <CheckCircleIcon className="h-6 w-6 text-emerald-400" aria-hidden="true" />
                ) : (
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                )}
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <Dialog.Title
                  as="h3"
                  className="text-lg leading-6 font-medium tracking-tight text-zinc-900 dark:text-zinc-50"
                >
                  {title}
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-zinc-500 dark:text-zinc-300 font-medium tracking-tight">{description}</p>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className={clsx(
                  'w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-6 py-2  text-base font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2  sm:ml-3 sm:w-auto sm:text-sm',
                  type === 'success'
                    ? 'bg-emerald-600 hover:bg-emerald-700 focus:ring-emerald-500'
                    : 'bg-red-600 hover:bg-red-700 focus:ring-red-500',
                )}
                onClick={handleSuccess}
              >
                {successMessage}
              </button>
              <button
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-zinc-300 dark:border-zinc-700 shadow-sm px-6 py-2 dark:bg-zinc-900 bg-white text-base font-medium text-zinc-700 dark:text-zinc-100 hover:bg-zinc-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={handleCancel}
                ref={cancelButtonRef}
              >
                {cancelMessage}
              </button>
            </div>
          </motion.div>
        </div>
      </Dialog>
    </>
  )
}
