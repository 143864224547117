import { AlertModal, ButtonLink, Card, DetailIcon, Rating, Title } from '@/components'
import { useMutation, useQuery } from '@apollo/client'
import { format, formatRelative, parseJSON } from 'date-fns'
import { AnimatePresence } from 'framer-motion'
import Image from 'next/image'
import { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { Carousel } from 'react-responsive-carousel'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

import acIcon from '../../../public/icons/3d/ac.png'
import accessibilityIcon from '../../../public/icons/3d/accessibility.png'
import dishwasherIcon from '../../../public/icons/3d/dishwasher.png'

import gasIcon from '../../../public/icons/3d/gas.png'
import hydroIcon from '../../../public/icons/3d/hydro.png'
import languagesIcon from '../../../public/icons/3d/languages.png'
import maintainIcon from '../../../public/icons/3d/maintain.png'
import noisyIcon from '../../../public/icons/3d/noisy.png'
import parkingIcon from '../../../public/icons/3d/parking.png'
import pestsIcon from '../../../public/icons/3d/pests.png'
import petsIcon from '../../../public/icons/3d/pets.png'
import responsiveIcon from '../../../public/icons/3d/responsive.png'
import waterIcon from '../../../public/icons/3d/water.png'
import LaundryImage from '../../../public/images/reviewicons/laundry.png'

import { DELETE_REVIEW_BY_ID, REVIEW_FOR_ID } from '@/queries'

const myLoader = ({ src }) => {
  return `${process.env.NEXT_PUBLIC_IMAGEKIT_URL_ENDPOINT}/${src}`
}

export const Review = ({ id }: any) => {
  const [reviewToDelete, setReviewToDelete] = useState<any>()

  const {
    data: reviews,
    error: reviewError,
    loading: reviewLoading,
  } = useQuery(REVIEW_FOR_ID, {
    variables: {
      reviewForListingId: id,
    },
  })
  const [deleteReviewById] = useMutation(DELETE_REVIEW_BY_ID)

  const deleteReview = async () => {
    try {
      deleteReviewById({
        variables: { reviewId: reviewToDelete },
        refetchQueries: [
          {
            query: REVIEW_FOR_ID,
            variables: {
              reviewForListingId: id,
            },
          },
        ],
      })
      setReviewToDelete(null)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="relative col-span-12 xl:mt-20 pt-16 ">
      <div className="mx-auto pb-4 sm:pb-24 lg:pb-10 ">
        <div className="sm:flex justify-between items-center">
          <div className="sm:w-2/3">
            <Title as="h2" size="lg" className="mb-1">
              Renter Reviews
            </Title>
            <p className="text-sm dark:text-zinc-200 text-zinc-600 font-medium">
              Review content contains views, experiences and opinions that are solely those of the individuals who have
              submitted them.
            </p>
          </div>
        </div>

        <div className="mt-6 sm:mt-16 lg:mt-8  lg:col-span-8">
          <h3 className="sr-only">Recent reviews</h3>

          <div className="flow-root">
            <div className="sm:mb-12  space-y-12">
              {reviewLoading ? (
                <div>
                  <Card className="w-full">
                    <div className="mt-1 flex items-center">
                      <Skeleton width="25%" />
                    </div>
                    <h4 className=" text-zinc-600 dark:text-zinc-400 mt-2 mb-1 text-sm font-semibold">
                      <Skeleton width="50%" height="1.2rem" />
                    </h4>
                    <div className="mt-4">
                      <p className="tracking-tight font-semibold sm:text-lg text-black dark:text-white">
                        <Skeleton />
                      </p>

                      <div className="mt-2 text-zinc-600 dark:text-zinc-400 text-base font-medium">
                        <Skeleton count={5} />
                      </div>
                    </div>
                  </Card>
                </div>
              ) : (
                reviews.reviewForListing.map((review) => {
                  return (
                    <div key={review.id} className="">
                      <Card className="w-full">
                        <div className="">
                          <div className="mt-1 flex items-center">
                            <Rating value={review.rating} />
                          </div>
                          <p className="sr-only">{review.rating} out of 5 stars</p>
                        </div>

                        <h4 className=" text-black dark:text-white mt-2 mb-1 text-sm font-semibold">
                          Posted {formatRelative(parseJSON(review.createdAt), new Date())}
                        </h4>

                        <div className="mt-4">
                          {!review.shareTenancy && review.tenancyStart ? (
                            <p className="tracking-tight font-semibold sm:text-lg text-black dark:text-white">
                              Rented from {format(new Date(review.tenancyStart), 'MMMM yyyy')}{' '}
                              {review.stillTenant && !review.tenancyEnd
                                ? ''
                                : `to ${format(new Date(review.tenancyEnd), 'MMMM yyyy')}`}
                            </p>
                          ) : null}
                          <div
                            className="mt-2 text-black dark:text-white text-base font-medium"
                            dangerouslySetInnerHTML={{ __html: review.content }}
                          />
                        </div>
                        {review.images.length ? (
                          <div className="my-6 rounded-md overflow-hidden">
                            <Carousel dynamicHeight centerMode>
                              {review.images?.map((image, idx) => (
                                <div key={image.fileName} className="relative">
                                  <div className="overflow-hidden shadow-subtle h-[500px]">
                                    <Image
                                      loader={myLoader}
                                      width="830"
                                      height="500"
                                      src={`tr:w-830,h-500,q-80${image.filePath}`}
                                      className="mb-0 leading-none"
                                    />
                                  </div>
                                </div>
                              ))}
                            </Carousel>
                          </div>
                        ) : null}
                        <div className="grid grid-cols-1 sm:grid-cols-2 mt-6 gap-10 border-t dark:border-zinc-700 border-zinc-100 pt-6">
                          {review.details?.gasIncluded || review.details?.gasAvgPrice != 0 ? (
                            <DetailIcon icon={gasIcon} theme="bg-green-200" name="gas" iconClass="stroke-green-800">
                              {review.details?.gasIncluded || review.details?.gasAvgPrice === 0
                                ? 'Gas Included'
                                : `Gas approx $${review.details?.gasAvgPrice} per month`}
                            </DetailIcon>
                          ) : null}

                          {review.details?.waterIncluded || review.details?.waterAvgPrice != 0 ? (
                            <DetailIcon
                              icon={waterIcon}
                              theme="bg-indigo-200"
                              name="water"
                              iconClass="stroke-indigo-800"
                            >
                              {review.details?.waterIncluded || review.details?.waterAvgPrice === 0
                                ? 'Water Included'
                                : `Water approx $${review.details?.waterAvgPrice} per month`}
                            </DetailIcon>
                          ) : null}

                          {review.details?.hydroIncluded || review.details?.hydroAvgPrice != 0 ? (
                            <DetailIcon
                              icon={hydroIcon}
                              theme="bg-orange-200"
                              name="hydro"
                              iconClass="stroke-orange-800"
                            >
                              {review.details?.hydroIncluded || review.details?.hydroAvgPrice === 0
                                ? 'Electricity Included'
                                : `Electricity approx $${review.details?.hydroAvgPrice} per month`}
                            </DetailIcon>
                          ) : null}

                          {typeof review.details?.dishwasherAvailable !== null ? (
                            <DetailIcon
                              icon={dishwasherIcon}
                              theme="bg-green-200"
                              name="dishwasher"
                              iconClass="stroke-green-800"
                            >
                              {review.details?.dishwasherAvailable ? 'Dishwasher Included' : 'No dishwasher'}
                            </DetailIcon>
                          ) : null}

                          {review.details?.airConditioningIncluded !== null ? (
                            <DetailIcon
                              icon={acIcon}
                              theme="bg-brand-50"
                              name="airconditioner"
                              iconClass="stroke-brand-800"
                            >
                              {review.details?.airConditioningIncluded
                                ? 'Air Conditioning Included'
                                : 'No air conditioning'}
                            </DetailIcon>
                          ) : null}

                          <DetailIcon
                            icon={parkingIcon}
                            theme="bg-emerald-200"
                            name="parking"
                            iconClass="stroke-emerald-800"
                          >
                            {review.details?.parkingLocation !== 'none' || review.details?.parkingAvailable
                              ? 'Parking Available'
                              : 'Parking not available'}
                            {review.details?.parkingLocation !== 'none' && review.details?.parkingAvgPrice
                              ? `: approx $${review.details?.parkingAvgPrice} per month`
                              : null}
                          </DetailIcon>
                        </div>
                        <div className="grid grid-cols-1 mt-8 gap-10">
                          {typeof review.details?.laundryIncluded !== null ||
                          typeof review.details?.laundryAvgPrice !== null ||
                          typeof review.details?.laundryLocation !== null ||
                          typeof review.details?.dryerAvgPrice !== null ? (
                            <DetailIcon
                              icon={LaundryImage}
                              theme="bg-lime-200"
                              iconClass="stroke-lime-800"
                              name="laundry"
                              metrics={
                                review.details?.laundryIncluded ? (
                                  <>
                                    <p className="text-zinc-600 dark:text-zinc-400 text-sm font-medium mt-1">
                                      {review.details?.laundryAvgPrice
                                        ? `Washing Machine $${review.details?.laundryAvgPrice} per load`
                                        : `Washing Machine included`}
                                    </p>
                                    <p className="text-zinc-600 dark:text-zinc-400 text-sm font-medium">
                                      {review.details?.dryerAvgPrice
                                        ? `Dryer $${review.details?.dryerAvgPrice} per load`
                                        : `Dryer included`}
                                    </p>
                                  </>
                                ) : null
                              }
                            >
                              {review.details?.laundryLocation !== 'none' || review.details?.laundryIncluded
                                ? 'Laundry Available'
                                : 'Laundry not available'}
                            </DetailIcon>
                          ) : null}

                          {review.details?.petsWelcome !== null || review.details?.petsWelcomeStory !== null ? (
                            <DetailIcon
                              icon={petsIcon}
                              theme="bg-yellow-200"
                              iconClass="stroke-yellow-800"
                              name="pets"
                              metrics={
                                review.details?.petsWelcomeStory ? (
                                  <p className="text-zinc-600 dark:text-zinc-400 text-sm font-medium mt-1">
                                    {review.details?.petsWelcomeStory}
                                  </p>
                                ) : null
                              }
                            >
                              {review.details?.petsWelcome ? 'Pets are welcome' : 'No pets welcome'}
                            </DetailIcon>
                          ) : null}

                          {review.details?.landlordResponsive !== null ||
                          review.details?.landlordResponsiveStory !== null ? (
                            <DetailIcon
                              icon={responsiveIcon}
                              theme="bg-cyan-200"
                              iconClass="stroke-cyan-800"
                              name="landlord"
                              metrics={
                                review.details?.landlordResponsiveStory ? (
                                  <p className="text-zinc-600 dark:text-zinc-400 text-sm font-medium mt-1">
                                    {review.details?.landlordResponsiveStory}
                                  </p>
                                ) : null
                              }
                            >
                              {review.details?.landlordResponsive
                                ? 'Landlord was responsive to requests'
                                : 'Landlord was not responsive to requests'}
                            </DetailIcon>
                          ) : null}

                          {review.details?.noisyNeighbours !== null || review.details?.noisyNeighboursStory !== null ? (
                            <DetailIcon
                              icon={noisyIcon}
                              theme="bg-emerald-200"
                              iconClass="stroke-emerald-800"
                              name="noisy"
                              metrics={
                                review.details?.noisyNeighboursStory ? (
                                  <p className="text-zinc-600 dark:text-zinc-400 text-sm font-medium mt-1">
                                    {review.details?.noisyNeighboursStory}
                                  </p>
                                ) : null
                              }
                            >
                              {review.details?.noisyNeighbours ? 'Neighbours are noisy.' : 'No noisy neighbours here.'}
                            </DetailIcon>
                          ) : null}

                          {review.details?.accessibility !== null || review.details?.accessibilityStory !== null ? (
                            <DetailIcon
                              icon={accessibilityIcon}
                              theme="bg-lime-200"
                              iconClass="stroke-lime-800"
                              name="accessibility"
                              metrics={
                                review.details?.accessibilityStory ? (
                                  <p className="text-zinc-600 dark:text-zinc-400 text-sm font-medium mt-1">
                                    {review.details?.accessibilityStory}
                                  </p>
                                ) : null
                              }
                            >
                              {review.details?.accessibility ? 'Accessibility Friendly.' : 'Not Accessiblity Friendly.'}
                            </DetailIcon>
                          ) : null}

                          {review.details?.landlordLanguagesStory !== null ||
                          review.details?.landlordLanguages !== null ? (
                            <DetailIcon
                              icon={languagesIcon}
                              theme="bg-rose-200"
                              iconClass="stroke-rose-800"
                              name="languages"
                              metrics={
                                review.details?.landlordLanguagesStory ? (
                                  <p className="text-zinc-600 dark:text-zinc-400 text-sm font-medium mt-1">
                                    {review.details?.landlordLanguagesStory}
                                  </p>
                                ) : null
                              }
                            >
                              {review.details?.landlordLanguages
                                ? 'Landlord supports other languages.'
                                : 'Landlord does not support other languages.'}
                            </DetailIcon>
                          ) : null}

                          {review.details?.pestsStory !== null || review.details?.pests !== null ? (
                            <DetailIcon
                              icon={pestsIcon}
                              theme="bg-teal-200"
                              iconClass="stroke-teal-800"
                              name="pests"
                              metrics={
                                review.details?.pestsStory ? (
                                  <p className="text-zinc-600 dark:text-zinc-400 text-sm font-medium mt-1">
                                    {review.details?.pestsStory}
                                  </p>
                                ) : null
                              }
                            >
                              {review.details?.pests
                                ? 'There were pests, insects or unwanted animals'
                                : 'No pests, insects or unwanted animals'}
                            </DetailIcon>
                          ) : null}

                          {review.details?.wellMaintained !== null || review.details?.wellMaintainedStory !== null ? (
                            <DetailIcon
                              icon={maintainIcon}
                              theme="bg-amber-200"
                              iconClass="stroke-amber-800"
                              name="maintain"
                              metrics={
                                review.details?.wellMaintainedStory ? (
                                  <p className="text-zinc-600 dark:text-zinc-400 text-sm font-medium mt-1">
                                    {review.details?.wellMaintainedStory}
                                  </p>
                                ) : null
                              }
                            >
                              {review.details?.wellMaintained
                                ? 'Property was well maintained'
                                : 'Property was not well maintained'}
                            </DetailIcon>
                          ) : null}
                        </div>
                        <div className="mt-16 flex justify-end items-center space-x-4 pb-4">
                          <ButtonLink small internal url={`/contact?report=listing-${id}`} className="flex">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                              />
                            </svg>
                            <span className="ml-1">Report</span>
                          </ButtonLink>
                        </div>
                      </Card>
                      {/* <Comments userId={data?.user.id} reviewId={review.id} comments={review.comments} listingId={id} /> */}
                    </div>
                  )
                })
              )}
            </div>
          </div>
        </div>
      </div>
      <AnimatePresence>
        {reviewToDelete ? (
          <AlertModal
            type="error"
            title="Are you sure you want to delete this review?"
            description="Reviews are helpful to others who may rent here. Instead, you can edit your review."
            successMessage="Delete"
            cancelMessage="Cancel"
            handleCancel={() => setReviewToDelete(null)}
            handleSuccess={deleteReview}
          />
        ) : null}
      </AnimatePresence>
    </div>
  )
}
