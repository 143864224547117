import { useRouter } from 'next/router'
import { useEffect } from 'react'

import useStore from '@/lib/store'
import { ApolloProvider } from '@apollo/client'
import * as Fathom from 'fathom-client'
import { SessionProvider } from 'next-auth/react'
import { Toaster } from 'react-hot-toast'
import shallow from 'zustand/shallow'

import apolloClient from '@/lib/apollo'

import { CookieBanner, ErrorBoundary, Footer, Notification, PageLoading, SearchBox } from '@/components'

import '../styles/global.css'

import Nav from '@/components/Nav'
import { AnimatePresence } from 'framer-motion'

function GNApp({ Component, pageProps: { session, ...pageProps }, err }: any) {
  const router = useRouter()
  const [startLoading, endLoading] = useStore((state) => [state.startLoading, state.endLoading], shallow)

  useEffect(() => {
    // Initialize Fathom when the app loads
    // Example: yourdomain.com
    //  - Do not include https://
    //  - This must be an exact match of your domain.
    //  - If you're using www. for your domain, make sure you include that here.
    Fathom.load('PLNULETS', {
      includedDomains: ['goodnbr.co'],
    })

    function onRouteChangeComplete() {
      Fathom.trackPageview()
    }
    // Record a pageview when route changes
    router.events.on('routeChangeComplete', onRouteChangeComplete)

    // Unassign event listener
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [router.events])

  useEffect(() => {
    router.events.on('routeChangeStart', startLoading)
    router.events.on('routeChangeComplete', endLoading)
    router.events.on('routeChangeError', endLoading)

    return () => {
      router.events.off('routeChangeStart', startLoading)
      router.events.off('routeChangeComplete', endLoading)
      router.events.off('routeChangeError', endLoading)
    }
  }, [router, startLoading, endLoading])

  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <SessionProvider session={session}>
          <Toaster position="bottom-center" />
          <Nav />
          <AnimatePresence exitBeforeEnter initial={false} onExitComplete={() => window.scrollTo(0, 0)}>
            <Component {...pageProps} err={err} />
          </AnimatePresence>
          <Footer />
          <Notification />
          <SearchBox />
          <CookieBanner />
          <PageLoading />
        </SessionProvider>
      </ApolloProvider>
    </ErrorBoundary>
  )
}

export default GNApp
