import * as Sentry from '@sentry/nextjs'
import React from 'react'

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
    Sentry.captureException(error)
    // You can also log error messages to an error reporting service here
  }

  render() {
    if ((this.state as any).errorInfo) {
      if (process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production') {
        return null
      } else {
        return (
          <div>
            <h2>Something went wrong.</h2>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {(this.state as any).error && (this.state as any).error.toString()}
              <br />
              {(this.state as any).errorInfo.componentStack}
            </details>
          </div>
        )
      }
      // Error path
    }
    // Normally, just render children
    return (this.props as any).children
  }
}
